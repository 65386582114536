import { Box } from '@hkexpressairwayslimited/ui';
import { YouTubeEmbed } from '@next/third-parties/google';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useMemo } from 'react';

type VideoProps = ComponentProps & {
  params: {
    videoId: string;
    autoplay: string;
    cc_lang_pref: string;
    cc_load_policy: string;
    color: string;
    controls: string;
    disablekb: string;
    enablejsapi: string;
    end: string;
    fs: string;
    hl: string;
    iv_load_policy: string;
    list: string;
    listType: string;
    loop: string;
    modestbranding: string;
    origin: string;
    playlist: string;
    playsinline: string;
    rel: string;
    start: string;
    widget_referrer: string;
    otherParams: string;
  };
};

const Video = ({ params }: VideoProps): JSX.Element => {
  const videoParams = useMemo(
    () =>
      [
        new URLSearchParams(
          Object.entries({
            autoPlay: params?.autoplay,
            cc_lang_pref: params?.cc_lang_pref,
            cc_load_policy: params?.cc_load_policy,
            color: params?.color,
            controls: params?.controls,
            disablekb: params?.disablekb,
            enablejsapi: params?.enablejsapi,
            end: params?.end,
            fs: params?.fs,
            hl: params?.hl,
            iv_load_policy: params?.iv_load_policy,
            list: params?.list,
            listType: params?.listType,
            loop: params?.loop,
            modestbranding: params?.modestbranding,
            origin: params?.origin,
            playlist: params?.playlist,
            playsinline: params?.playsinline,
            rel: params?.rel,
            start: params?.start,
            widget_referrer: params?.widget_referrer,
          }).reduce(
            (acc, [key, value]) => {
              if (value) {
                acc[key] = value;
              }
              return acc;
            },
            {} as Record<string, string>
          )
        ).toString(),
        new URLSearchParams(params?.otherParams).toString(),
      ]
        .filter((param): param is string => !!param)
        .join('&') || undefined,
    [params]
  );

  return (
    <Box
      className={`${params?.styles}`}
      sx={{
        width: '100%',
        height: {
          xs: '190px',
          sm: '347px',
          md: '702px',
        },
      }}
    >
      <YouTubeEmbed
        style="width: 100%; height: 100%; max-width: 100%;"
        videoid={params?.videoId}
        params={videoParams}
      ></YouTubeEmbed>
    </Box>
  );
};

export default withDatasourceCheck()<VideoProps>(Video);
