import { Footer as FooterUi } from '@hkexpressairwayslimited/ui';
import {
  Field,
  ImageField,
  Placeholder,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type ContentBlockProps = ComponentProps & {
  fields: {
    subscriptionFieldLabel: Field<string>;
    subscribeBtnLabel: Field<string>;
    logo: ImageField;
    copyrightLabel: Field<string>;
    socialShareTitle: Field<string>;
    subscribeFieldHelper: Field<string>;
    emailFieldRequiredMsg: Field<string>;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const Footer = ({ fields, rendering }: ContentBlockProps): JSX.Element => {
  const footerProps = {
    subscriptionField: {
      label: <Text field={fields?.subscriptionFieldLabel} />,
      helperText: <Text field={fields?.subscribeFieldHelper} />,
    },
    subscribeBtnLabel: <Text field={fields?.subscribeBtnLabel} />,
    logo: (
      <JssImage
        field={fields?.logo}
        width={0}
        height={0}
        style={{ width: 'auto', height: '100%' }}
      />
    ),
    emailFieldRequiredMsg: fields?.emailFieldRequiredMsg?.value,
    editor: {
      emailFieldRequiredMsg: <Text field={fields?.emailFieldRequiredMsg} />,
    },
    copyrightLabel: <Text field={fields?.copyrightLabel} />,
    navGroups: <Placeholder name="nav-groups" rendering={rendering} />,
    socialShareTitle: <Text field={fields?.socialShareTitle} />,
    socialShareChannels: <Placeholder name="social-share-channels" rendering={rendering} />,
  };

  return (
    <>
      <FooterUi {...footerProps} />
    </>
  );
};

export default withDatasourceCheck()<ContentBlockProps>(Footer);
