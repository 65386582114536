import { Container } from '@hkexpressairwayslimited/ui';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContentBlockProps = ComponentProps;

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const MainContent = ({ rendering }: ContentBlockProps): JSX.Element => {
  return (
    <Container>
      <Placeholder name="main-content" rendering={rendering} />
    </Container>
  );
};

export default withDatasourceCheck()<ContentBlockProps>(MainContent);
