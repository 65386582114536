import { FooterShareChannel as FooterShareChannelUi } from '@hkexpressairwayslimited/ui';
import {
  ImageField,
  Link,
  LinkField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type FooterShareChannelProps = ComponentProps & {
  fields: {
    image: ImageField;
    link: LinkField;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const FooterShareChannel = ({ fields }: FooterShareChannelProps): JSX.Element => {
  return (
    <FooterShareChannelUi>
      <Link field={fields?.link}>
        <JssImage
          field={fields?.image}
          width={0}
          height={0}
          style={{ width: '100%', height: 'auto' }}
        />
      </Link>
    </FooterShareChannelUi>
  );
};

export default withDatasourceCheck()<FooterShareChannelProps>(FooterShareChannel);
