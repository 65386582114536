import { Box } from '@hkexpressairwayslimited/ui';
import { Stack, styled } from '@mui/material';
import {
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type TableRowProps = ComponentProps;

const StyledPlaceholderStack = styled(Stack)(() => ({
  width: '100%',
  padding: '12px 0',
  '.table-cell': {
    flex: 1,
    borderTop: '1px solid #D6D6D8',
  },
  '.table-cell: last-of-type': {
    borderRight: 'none',
  },
}));

const TableRow = (props: TableRowProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  return (
    <>
      <Box className="table-row" sx={{ display: 'table-row' }}>
        {isPageEditing ? (
          <StyledPlaceholderStack direction="row" justifyContent="space-between">
            <Placeholder name="table-row-placeholders" rendering={props.rendering} />
          </StyledPlaceholderStack>
        ) : (
          <Placeholder name="table-row-placeholders" rendering={props.rendering} />
        )}
      </Box>
    </>
  );
};

export default withDatasourceCheck()<TableRowProps>(TableRow);
