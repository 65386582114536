import { EFont, EPixel, Font, constants } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import {
  Field,
  Link,
  LinkFieldValue,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type CardMultiTextProps = ComponentProps & {
  fields: {
    title: Field<string>;
    date: Field<string>;
    from: Field<string>;
    money: Field<string>;
    link: LinkFieldValue;
  };
};

const CardMultiText = (props: CardMultiTextProps): JSX.Element => {
  return (
    <Link
      className={`${props.params?.styles}`}
      style={{ textDecoration: 'none' }}
      field={props.fields?.link}
    >
      <Stack
        justifyContent="center"
        sx={{
          width: '100%',
          minHeight: '140px',
          padding: {
            xs: '0 16px',
            sm: '0 58px',
          },
          borderRadius: EPixel.px16,
          border: '1px solid #D6D6D8',
          backgroundColor: '#E2D5E9',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Font variant={EFont.h3} fontWeight="bolder" sx={{ color: '#000' }}>
          <Text field={props.fields?.title} />
        </Font>
        <Font
          variant={EFont.p2}
          sx={{
            fontFamily: constants.varFontPlutoSans,
            mt: EPixel.px4,
            color: '#000',
          }}
        >
          <Text field={props.fields?.date} />
        </Font>
        <Stack direction="row" sx={{ mt: EPixel.px4 }}>
          <Font
            variant={EFont.p2}
            sx={{
              fontFamily: constants.varFontPlutoSans,
              color: '#702B91',
            }}
          >
            <Text field={props.fields?.from} />
          </Font>
          <Font
            fontWeight="fontWeightBold"
            variant={EFont.h3}
            sx={{
              color: '#702B91',
              ml: EPixel.px4,
              mt: '-4px',
            }}
          >
            <Text field={props.fields?.money} />
          </Font>
        </Stack>
      </Stack>
    </Link>
  );
};

export default withDatasourceCheck()<CardMultiTextProps>(CardMultiText);
