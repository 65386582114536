import { AppContext, Image } from '@hkexpressairwayslimited/ui';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { ImageProps } from 'next/image';
import { ComponentProps, useContext } from 'react';

export const JssImage = (props: ComponentProps<typeof NextImage>) => {
  const { isInEditor, isPreview } = useContext(AppContext);
  const imgProps = {
    ...(props.field?.value || props.field || {}),
    ...props,
  } as ImageProps;
  const { src, ...restEditingProps } = imgProps;

  console.log('props', props);

  return isInEditor || isPreview ? (
    <NextImage {...restEditingProps} unoptimized={isInEditor || isPreview} />
  ) : (
    <Image {...imgProps} />
  );
};
