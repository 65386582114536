import { Box } from '@hkexpressairwayslimited/ui';
import {
  Field,
  RichText,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type HtmlContainerProps = ComponentProps & {
  fields: {
    content: Field<string>;
  };
};

const HtmlContainer = (props: HtmlContainerProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const value = props.fields?.content?.value;

  return (
    <>
      {isPageEditing ? (
        <Box sx={{ border: '1px solid #D6D6D8' }}>
          <RichText field={props.fields?.content} />
        </Box>
      ) : (
        <Box
          dangerouslySetInnerHTML={{ __html: value }}
          className={`${props.params?.styles}`}
        ></Box>
      )}
    </>
  );
};

export default withDatasourceCheck()<HtmlContainerProps>(HtmlContainer);
