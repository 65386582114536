import { Box, EFont, EPixel, Font } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import { Field, ImageField, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type JourneyOverviewProps = ComponentProps & {
  fields: {
    imageIconLeft: ImageField;
    imageIconRight: ImageField;
    text: Field<string>;
  };
};

const JourneyOverview = (props: JourneyOverviewProps): JSX.Element => {
  return (
    <Box className={`${props.params?.styles}`}>
      <Font
        sx={{ textAlign: 'center', color: '#702B91', mb: '-8px' }}
        variant={EFont.p1}
        fontWeight="bolder"
      >
        <Text field={props.fields?.text} />
      </Font>
      <Stack direction="row" alignItems="center">
        <Box>
          <JssImage width="32" height="32" field={props.fields?.imageIconLeft} />
        </Box>
        <Box sx={{ borderTop: '1px dotted #702B91', flex: 1, position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              padding: EPixel.px4,
              backgroundColor: '#fff',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box
              sx={{
                width: EPixel.px8,
                height: EPixel.px8,
                border: '2px solid #702B91',
                borderRadius: '50%',
              }}
            ></Box>
          </Box>
        </Box>
        <Box>
          <JssImage width="32" height="32" field={props.fields?.imageIconRight} />
        </Box>
      </Stack>
    </Box>
  );
};

export default withDatasourceCheck()<JourneyOverviewProps>(JourneyOverview);
