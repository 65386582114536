import { Box } from '@hkexpressairwayslimited/ui';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type TableCellProps = ComponentProps;

const TableCell = (props: TableCellProps): JSX.Element => {
  return (
    <Box
      className={`table-cell ${props.params?.styles}`}
      sx={{
        textAlign: 'center',
        display: 'table-cell',
        borderBottom: '1px solid #D6D6D8',
        borderRight: '1px solid #D6D6D8',
        padding: '14px',
        verticalAlign: 'middle',
      }}
    >
      <Placeholder name="table-cell-placeholders" rendering={props.rendering} />
    </Box>
  );
};

export default withDatasourceCheck()<TableCellProps>(TableCell);
