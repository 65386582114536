import { Box, EPixel, useMobileQuery } from '@hkexpressairwayslimited/ui';
import { Stack, styled } from '@mui/material';
import {
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useEffect, useRef, useState } from 'react';

type TableProps = ComponentProps;

const StyledTable = styled(Box)(() => ({
  display: 'table',
  width: '100%',
  border: '1px solid #D6D6D8',
  borderRadius: EPixel.px16,
  tableLayout: 'fixed',
  overflow: 'hidden',
  '.table-row: first-child': {
    '.table-cell: first-child': {
      borderRadius: `16px 0 0 0`,
    },
    '.table-cell: last-child': {
      borderRadius: `0 16px 0 0`,
    },
    '.table-cell': {
      backgroundColor: '#F6F4F9',
      fontWeight: 'bold',
      color: '#702B91',
    },
  },
  '.table-row: last-child': {
    '.table-cell': {
      borderBottom: 'none',
    },
  },
  '.table-row': {
    '.table-cell: last-child': {
      borderRight: 'none',
    },
  },
  '.table-cell img': {
    width: 'initial',
  },
  '.table-cell p': {
    margin: 0,
  },
}));

interface TableContentItemType {
  contentTitle: string;
  contentValue: string;
}
interface TableContentType {
  title: string;
  content: (TableContentItemType | null)[];
}

const Table = (props: TableProps): JSX.Element => {
  const isFare = props.params?.isFare;
  const [tableContent, setTableContent] = useState<null | TableContentType[]>(null);
  const tableRef = useRef<HTMLElement>(null);
  const isMobile = useMobileQuery();
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  useEffect(() => {
    if (isMobile && isFare) {
      const rows = tableRef.current?.querySelectorAll('.table-row');
      const cells0 = rows?.[0]?.querySelectorAll('.table-cell');

      if (!rows) return;
      if (!cells0) return;

      const l = cells0?.length - 1;
      if (l > 0) {
        const divArr = Array.from({ length: l }).map(() => {
          return { title: '', content: [] };
        });
        for (let i = 0; i < rows.length; i++) {
          const cells = rows[i].querySelectorAll('.table-cell');
          let contentTitle = '';
          for (let j = 0; j < cells.length; j++) {
            if (i === 0 && j !== 0) {
              (divArr[j - 1] as TableContentType).title = cells[j]?.innerHTML || '';
            } else {
              if (j === 0) {
                contentTitle = cells[j]?.innerHTML || '';
              } else {
                const contentValue = cells[j]?.innerHTML || '';
                contentValue &&
                  (divArr[j - 1] as TableContentType).content?.push({
                    contentTitle,
                    contentValue,
                  });
              }
            }
          }
        }
        setTableContent(divArr as TableContentType[]);
      }
    }
  }, [isMobile]);
  return (
    <Box sx={{ overflowX: 'auto' }} className={`${props.params?.styles}`}>
      <StyledTable
        ref={tableRef}
        sx={
          isMobile && isFare
            ? { position: 'absolute', opacity: 0, zIndex: '-999', width: 0 }
            : { padding: isPageEditing ? '12px 0' : 0, minWidth: '650px' }
        }
      >
        <Placeholder name="table-placeholders" rendering={props.rendering} />
      </StyledTable>
      {isMobile && isFare ? (
        <Box>
          {tableContent?.map((item, index: number) => {
            return (
              <Box key={index} sx={{ mt: index === 0 ? 0 : EPixel.px16 }}>
                <Box
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  sx={{ padding: '14px 0', fontWeight: 'bold', color: '#702B91' }}
                ></Box>
                {item.content?.map((content: TableContentItemType, index: number) => {
                  return (
                    <Stack
                      key={index}
                      sx={{ mt: EPixel.px12 }}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box dangerouslySetInnerHTML={{ __html: content.contentTitle }}></Box>
                      <Box dangerouslySetInnerHTML={{ __html: content.contentValue }}></Box>
                    </Stack>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};

export default withDatasourceCheck()<TableProps>(Table);
