import { Box, EPixel } from '@hkexpressairwayslimited/ui';
import { styled } from '@mui/material';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type HeadlineProps = ComponentProps & {
  fields: {
    content: Field<string>;
  };
};

const StyledBox = styled(Box)(({ theme }) => ({
  fontSize: '26px',
  lineHeight: EPixel.px32,
  fontWeight: 'bolder',
  color: '#702B91',
  [theme.breakpoints.down('md')]: {
    fontSize: EPixel.px20,
    lineHeight: EPixel.px28,
  },
}));

const Headline = (props: HeadlineProps): JSX.Element => {
  const fontColor = props.params?.fontColor || '#702B91';
  return (
    <StyledBox sx={{ color: fontColor }} className={`${props.params?.styles}`}>
      <Text field={props.fields?.content} />
    </StyledBox>
  );
};

export default withDatasourceCheck()<HeadlineProps>(Headline);
