import { Box, EPixel } from '@hkexpressairwayslimited/ui';
import { styled } from '@mui/material';
import {
  Field,
  ImageField,
  Link,
  LinkFieldValue,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& img': {
    objectFit: 'cover',
    borderRadius: EPixel.px16,
    maxWidth: '100%',
    width: '100%',
    height: 'auto',
    // height: '294px',
    // [theme.breakpoints.down('lg')]: {
    //   height: '220px',
    // },
    // [theme.breakpoints.down('md')]: {
    //   height: '280px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   height: '150px',
    // },
  },
}));

type ImageTextProps = ComponentProps & {
  fields: {
    image: ImageField;
    text: Field<string>;
    link: LinkFieldValue;
  };
};

const ImageText = (props: ImageTextProps): JSX.Element => {
  return (
    <StyledBox className={`${props.params?.styles}`}>
      <Link style={{ width: '100%', textDecoration: 'none' }} field={props.fields?.link}>
        <Box
          sx={{
            width: '100%',
            height: 0,
            paddingBottom: '100%',
            overflow: 'hidden',
            borderRadius: EPixel.px16,
          }}
        >
          <JssImage field={props.fields?.image} />
        </Box>
        <Box
          sx={{
            color: '#702B91',
            fontWeight: 'bolder',
            fontSize: EPixel.px22,
            lineHeight: EPixel.px32,
            mt: EPixel.px4,
          }}
        >
          <Text field={props.fields?.text} />
        </Box>
      </Link>
    </StyledBox>
  );
};

export default withDatasourceCheck()<ImageTextProps>(ImageText);
