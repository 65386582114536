import { Header } from '@hkexpressairwayslimited/ui';
import { Field, Placeholder, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContentBlockProps = ComponentProps & {
  fields: {
    label: Field<string>;
  };
  params: {
    horizontal: boolean;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const NavItem = ({ fields, params, rendering }: ContentBlockProps): JSX.Element => {
  const { NavItem } = Header;

  return (
    <NavItem
      label={<Text field={fields?.label} />}
      horizontal={params.horizontal}
      navMenuGroups={<Placeholder name="nav-menu-groups" rendering={rendering} />}
    />
  );
};

export default withDatasourceCheck()<ContentBlockProps>(NavItem);
