import { ButtonVariant, Button as UiButton, useMobileQuery } from '@hkexpressairwayslimited/ui';
import {
  Field,
  Link,
  LinkFieldValue,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ButtonProps = ComponentProps & {
  fields: {
    label: Field<string>;
    link: LinkFieldValue;
  };
};
const buttonVariant: { [key: string]: string } = {
  primary: ButtonVariant.Primary,
  secondary: ButtonVariant.Secondary,
};
const buttonVariantArr = ['primary', 'secondary'];
const Button = (props: ButtonProps): JSX.Element => {
  const variant = props.params?.variant;
  const isValid = buttonVariantArr.includes(variant);
  const isMobile = useMobileQuery();
  return (
    <Link field={props.fields?.link} className={isMobile ? 'col-12' : ''}>
      <UiButton
        fullWidth={isMobile}
        id={props.rendering.uid}
        variant={isValid ? (buttonVariant[variant] as ButtonVariant) : ButtonVariant.Primary}
      >
        <Text field={props.fields?.label} />
      </UiButton>
    </Link>
  );
};

export default withDatasourceCheck()<ButtonProps>(Button);
