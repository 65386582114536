import { Box, EPixel } from '@hkexpressairwayslimited/ui';
import { Grid } from '@mui/material';
import {
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContainerProps = ComponentProps;

type directionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';

const directionArr = ['row', 'row-reverse', 'column', 'column-reverse'];
const alignItemsArr = ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'];
const justifyContentArr = [
  'flex-start',
  'center',
  'flex-end',
  'space-between',
  'space-around',
  'space-evenly',
];
const spacingArr = [0, 0.5, 1, 2, 3, 4, 8, 12];

const Container = (props: ContainerProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const marginDesktop = props.params?.marginDesktop || 0;
  const paddingDesktop = props.params?.paddingDesktop || 0;
  const marginTablet = props.params?.marginTablet || 0;
  const paddingTablet = props.params?.paddingTablet || 0;
  const marginMobile = props.params?.marginMobile || 0;
  const paddingMobile = props.params?.paddingMobile || 0;
  const spacingValue = Number(props.params?.spacing);
  const direction = directionArr.includes(props.params?.direction)
    ? props.params?.direction
    : 'row';
  const alignItems = alignItemsArr.includes(props.params?.alignItems)
    ? props.params?.alignItems
    : '';
  const justifyContent = justifyContentArr.includes(props.params?.justifyContent)
    ? props.params?.justifyContent
    : '';
  const spacing = spacingArr.includes(spacingValue) ? spacingValue : 0;
  return (
    <Box
      sx={{
        padding: {
          md: paddingDesktop || paddingTablet || paddingMobile,
          sm: paddingTablet || paddingMobile,
          xs: paddingMobile,
        },
        margin: {
          md: marginDesktop || marginTablet || marginMobile,
          sm: marginTablet || marginMobile,
          xs: marginMobile,
        },
      }}
      className={`${props.params?.styles}`}
    >
      <Grid
        container
        spacing={spacing}
        direction={direction as directionType}
        alignItems={alignItems}
        justifyContent={justifyContent}
        sx={isPageEditing ? { padding: `${EPixel.px16} 0` } : {}}
      >
        <Placeholder name="container-placeholders" rendering={props.rendering} />
      </Grid>
    </Box>
  );
};

export default withDatasourceCheck()<ContainerProps>(Container);
