import { Header } from '@hkexpressairwayslimited/ui';
import {
  Link,
  LinkField,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContentBlockProps = ComponentProps & {
  fields: {
    link: LinkField;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const NavMenuLink = ({ fields }: ContentBlockProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const { text, ...linkProps } = fields?.link.value || {};
  const { NavMenuLink } = Header;

  return isPageEditing ? (
    <Link field={fields?.link} />
  ) : (
    <NavMenuLink {...linkProps}>{text}</NavMenuLink>
  );
};

export default withDatasourceCheck()<ContentBlockProps>(NavMenuLink);
