import { EPixel } from '@hkexpressairwayslimited/ui';
import { Grid } from '@mui/material';
import {
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContainerItemProps = ComponentProps;

const ContainerItem = (props: ContainerItemProps): JSX.Element => {
  const desktopColumn = Number(props.params?.desktopColumn);
  const tabletColumn = Number(props.params?.tabletColumn);
  const mobileColumn = Number(props.params?.mobileColumn) || 12;
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  return (
    <Grid
      className="grid-item"
      item
      container
      sx={isPageEditing ? { padding: `${EPixel.px8} 0` } : {}}
      md={desktopColumn || tabletColumn || mobileColumn}
      sm={tabletColumn || mobileColumn}
      xs={mobileColumn}
    >
      <Placeholder name="container-item-placeholders" rendering={props.rendering} />
    </Grid>
  );
};

export default withDatasourceCheck()<ContainerItemProps>(ContainerItem);
