import {
  I18nStr as I18nStrWrapper,
  useTransContent as useTransContentWrapper,
} from '@hkexpressairwayslimited/ui';
import { useI18n as useTranslationFunc } from 'next-localization';
import { useMemo } from 'react';

export type I18nStr = I18nStrWrapper<typeof useTranslationFunc>;
export const useTransContent = (...args: Parameters<typeof useTranslationFunc>) => {
  const translationHook = useTranslationFunc(...args);
  const transContentHook = useTransContentWrapper(translationHook);
  const transformedHook = useMemo(
    () => ({
      ...transContentHook,
      tPlain: (...args: Parameters<(typeof transContentHook)['tPlain']>) => {
        const [firstArg, ...otherArgs] = args;
        const content = transContentHook.tPlain(
          typeof firstArg === 'string' ? firstArg.replaceAll('.', '') : firstArg,
          ...otherArgs
        );

        return content || firstArg.toString();
      },
      t: (...args: Parameters<(typeof transContentHook)['t']>) => {
        const [firstArg, ...otherArgs] = args;
        const content = transContentHook.t(
          typeof firstArg === 'string' ? firstArg.replaceAll('.', '') : firstArg,
          ...otherArgs
        );
        return Array.isArray(content) && content.length === 0 ? firstArg.toString() : content;
      },
    }),
    [transContentHook]
  );

  return transformedHook;
};
