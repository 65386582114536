import { Box } from '@hkexpressairwayslimited/ui';
import { styled } from '@mui/material';
import {
  ImageField,
  Link,
  LinkFieldValue,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

const StyledBox = styled(Box)(({ isFullWidth }: { isFullWidth: boolean }) => ({
  '& img': {
    maxWidth: '100%',
    height: 'initial',
    width: isFullWidth ? '100%' : 'initial',
  },
}));

type ImageProps = ComponentProps & {
  fields: {
    image: ImageField;
    link: LinkFieldValue;
  };
};

const Image = (props: ImageProps): JSX.Element => {
  const isClickable = props.params?.isClickable;
  const isFullWidth = Boolean(props.params?.isFullWidth);
  return (
    <StyledBox isFullWidth={isFullWidth} className={`${props.params?.styles}`}>
      {isClickable ? (
        <Link field={props.fields?.link}>
          <JssImage field={props.fields?.image} />
        </Link>
      ) : (
        <JssImage field={props.fields?.image} />
      )}
    </StyledBox>
  );
};

export default withDatasourceCheck()<ImageProps>(Image);
