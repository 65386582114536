import { Box, EPixel } from '@hkexpressairwayslimited/ui';
import { styled } from '@mui/material';
import {
  Field,
  Link,
  LinkFieldValue,
  RichText,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ParagraphProps = ComponentProps & {
  fields: {
    content: Field<string>;
    link: LinkFieldValue;
  };
};

const StyledBox = styled(Box)(() => ({
  fontSize: EPixel.px16,
  lineHeight: EPixel.px24,
}));

const Paragraph = (props: ParagraphProps): JSX.Element => {
  const fontColor = props.params?.fontColor || '#000';
  const isClickable = props.params?.isClickable;
  return (
    <>
      {isClickable ? (
        <Link
          className={`${props.params?.GridParameters} ${props.params?.styles}`}
          style={{ textDecoration: 'none' }}
          field={props.fields?.link}
        >
          <StyledBox sx={{ color: '#702B91', fontWeight: 'bolder' }}>
            <RichText field={props.fields?.content} />
          </StyledBox>
        </Link>
      ) : (
        <StyledBox
          sx={{ color: fontColor }}
          className={`${props.params?.GridParameters} ${props.params?.styles}`}
        >
          <RichText field={props.fields?.content} />
        </StyledBox>
      )}
    </>
  );
};

export default withDatasourceCheck()<ParagraphProps>(Paragraph);
