import { Box, EFont, EPixel, Font, constants } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import {
  Field,
  ImageField,
  Link,
  LinkFieldValue,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type CardIconProps = ComponentProps & {
  fields: {
    imageIcon: ImageField;
    title: Field<string>;
    link: LinkFieldValue;
    subText: Field<string>;
  };
};

const CardIcon = (props: CardIconProps): JSX.Element => {
  const isPurple = props.params?.isPurple;
  const isBorder = props.params?.isBorder;
  const isClickable = props.params?.isClickable;
  const hasSubText = props.params?.hasSubText;
  const boxContent = (
    <Box
      sx={{
        height: '100%',
        borderRadius: EPixel.px16,
        border: isBorder || isClickable ? '1px solid #D6D6D8' : 'none',
        backgroundColor: isPurple ? '#F6F4F9' : '#ffffff',
        overflow: 'hidden',
        color: '#000',
        '& :hover': {
          backgroundColor: isClickable ? '#F6F4F9' : isPurple ? '#F6F4F9' : '#ffffff',
        },
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: '140px',
          padding: `0 ${EPixel.px8}`,
          height: '100%',
          '& img': {
            maxWidth: '100%',
          },
        }}
      >
        <JssImage field={props.fields?.imageIcon} />
        <Font
          sx={{
            mt: EPixel.px4,
            textAlign: 'center',
          }}
          variant={EFont.p1}
          fontWeight="bolder"
        >
          <Text field={props.fields?.title} />
        </Font>
        {hasSubText && (
          <Font
            sx={{
              mt: EPixel.px4,
              textAlign: 'center',
              fontFamily: constants.varFontPlutoSans,
            }}
            variant={EFont.p2}
          >
            <Text field={props.fields?.subText} />
          </Font>
        )}
      </Stack>
    </Box>
  );
  return (
    <>
      {isClickable ? (
        <Link
          className={`${props.params?.styles}`}
          style={{ textDecoration: 'none' }}
          field={props.fields?.link}
        >
          {boxContent}
        </Link>
      ) : (
        <Box className={`${props.params?.styles}`}>{boxContent}</Box>
      )}
    </>
  );
};

export default withDatasourceCheck()<CardIconProps>(CardIcon);
