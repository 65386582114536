import { Header } from '@hkexpressairwayslimited/ui';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type NavMenuColumnProps = ComponentProps;

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const NavMenuColumn = ({ rendering }: NavMenuColumnProps): JSX.Element => {
  const { NavMenuColumn } = Header;

  return (
    <NavMenuColumn>
      <Placeholder name="nav-menu-column-groups" rendering={rendering} />
    </NavMenuColumn>
  );
};

export default withDatasourceCheck()<NavMenuColumnProps>(NavMenuColumn);
