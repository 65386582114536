import { FooterNavGroup as FooterNavGroupUi } from '@hkexpressairwayslimited/ui';
import { Field, Placeholder, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type FooterNavGroupProps = ComponentProps & {
  fields: {
    groupLabel: Field<string>;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const FooterNavGroup = ({ fields, rendering }: FooterNavGroupProps): JSX.Element => {
  return (
    <FooterNavGroupUi
      groupLabel={<Text field={fields?.groupLabel} />}
      navLinks={<Placeholder name="nav-links" rendering={rendering} />}
    />
  );
};

export default withDatasourceCheck()<FooterNavGroupProps>(FooterNavGroup);
