import {
  Box,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  EPixel,
  SvgIcon,
} from '@hkexpressairwayslimited/ui';
import { Stack, styled } from '@mui/material';
import {
  Placeholder,
  getChildPlaceholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { constructSliderHtml } from './SliderCard';

const StyledSlider = styled(Slider)(({ isLongBanner }: { isLongBanner: boolean }) => ({
  '.slick-slide > div': {
    display: 'flex',
  },
  '.slick-dots': {
    position: isLongBanner ? 'initial' : 'absolute',
    marginTop: isLongBanner ? '30px' : 0,
    display: 'flex',
    '& > li': {
      width: 'initial',
      height: 'initial',
    },
    bottom: EPixel.px16,
  },
  '.slick-slide img': {
    width: '100%',
    height: 'initial',
    borderRadius: isLongBanner ? EPixel.px16 : 0,
  },
}));

type SliderBannerProps = ComponentProps;

const SliderBanner = (props: SliderBannerProps): JSX.Element => {
  const isLongBanner = Boolean(props.params?.isLongBanner);
  const sliderRef = useRef<Slider>(null);
  const placeHolderRef = useRef<HTMLElement | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const placeholders = getChildPlaceholder(props.rendering, 'slider-banner-placeholders');
  const page = placeholders.length;
  // const size = props.params?.size;
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    afterChange: (currentSlide: number) => {
      setCurrentSlide(currentSlide);
    },
    appendDots: (dots: ReactNode) => {
      return page > 1 ? (
        <Stack direction="row" alignItems="center" justifyContent="center">
          {isLongBanner && (
            <Button custom>
              <SvgIcon
                sx={{
                  color: '#8A8F9D',
                  opacity: currentSlide === 0 ? '0.3' : 1,
                }}
                onClick={
                  currentSlide === 0
                    ? () => {}
                    : () => {
                        sliderRef?.current?.slickPrev();
                      }
                }
              >
                <ChevronLeftIcon />
              </SvgIcon>
            </Button>
          )}
          {dots}
          {isLongBanner && (
            <Button custom>
              <SvgIcon
                sx={{ color: '#8A8F9D', opacity: currentSlide === page - 1 ? '0.3' : 1 }}
                onClick={
                  currentSlide === page - 1
                    ? () => {}
                    : () => {
                        sliderRef?.current?.slickNext();
                      }
                }
              >
                <ChevronRightIcon />
              </SvgIcon>
            </Button>
          )}
        </Stack>
      ) : (
        <div></div>
      );
    },
    customPaging: function (i: number) {
      const sytle =
        currentSlide === i
          ? {
              width: EPixel.px48,
              height: EPixel.px16,
              borderRadius: EPixel.px8,
              backgroundColor: '#21B9DA',
              border: '1px solid #21B9DA',
            }
          : {
              width: EPixel.px16,
              height: EPixel.px16,
              borderRadius: EPixel.px16,
              border: '1px solid #21B9DA',
              backgroundColor: '#ffffff',
            };
      return isLongBanner ? (
        <Box
          sx={{
            fontWeight: currentSlide === i ? 'bolder' : 'initial',
            color: currentSlide === i ? '#702B91' : '#8A8F9D',
            margin: '0 16px',
          }}
        >
          {i + 1}
        </Box>
      ) : (
        <Box sx={sytle}></Box>
      );
    },
  };
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const [placeHolderArr, setPlaceHolderArr] = useState<JSX.Element[]>([]);
  useEffect(() => {
    constructSliderHtml(placeHolderRef, (elementArr) => {
      setPlaceHolderArr(elementArr);
    });
  }, []);
  // const imgBoxH = isLongBanner
  //   ? '170px'
  //   : size === 'small' || isMobile
  //     ? '300px'
  //     : size === 'medium' || isTablet
  //       ? '360px'
  //       : '470px';
  return (
    <>
      {isPageEditing ? (
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            '& > div': {
              width: '100% !important',
            },
          }}
        >
          <Placeholder name={`slider-banner-placeholders`} rendering={props.rendering} />
        </Box>
      ) : (
        <StyledSlider
          isLongBanner={isLongBanner}
          className={`${props.params?.styles}`}
          ref={sliderRef}
          {...settings}
        >
          {placeHolderArr}
        </StyledSlider>
      )}
      <Box
        sx={{ position: 'absolute', zIndex: -999, opacity: 0, height: 0, overflow: 'hidden' }}
        ref={placeHolderRef}
      >
        <Placeholder name={`slider-banner-placeholders`} rendering={props.rendering} />
      </Box>
    </>
  );
};

export default withDatasourceCheck()<SliderBannerProps>(SliderBanner);
