import { Box, ChevronRightIcon, EPixel } from '@hkexpressairwayslimited/ui';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';
import { Field, Placeholder, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const StyledMuiAccordion = styled(MuiAccordion)(() => ({
  '&:before': {
    display: 'none',
  },
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'initial',
  },
  '.MuiAccordionSummary-root': {
    padding: `0 0 0 ${EPixel.px16}`,
    borderBottom: '1px solid #702B91',
  },
  '.MuiAccordionSummary-content': {
    margin: `${EPixel.px24} 0`,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: `${EPixel.px24} 0`,
  },
  '.MuiAccordionDetails-root': {
    padding: `0 0 0 ${EPixel.px16}`,
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    width: EPixel.px32,
    height: EPixel.px32,
    transition: 'none',
  },
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& p': {
    margin: 0,
  },
}));

type ListItemProps = ComponentProps & {
  fields: {
    title: Field<string>;
    content: Field<string>;
  };
};

const ListItem = (props: ListItemProps): JSX.Element => {
  const isClickable = props.params?.isClickable;
  return (
    <Box className={`${props.params?.styles}`}>
      {!isClickable ? (
        <Box
          sx={{
            fontSize: EPixel.px16,
            lineHeight: EPixel.px24,
            fontWeight: 'bolder',
            padding: `${EPixel.px24} ${EPixel.px16}`,
            borderBottom: '1px solid #702B91',
          }}
        >
          <Text field={props.fields?.title} />
        </Box>
      ) : (
        <StyledMuiAccordion>
          <MuiAccordionSummary expandIcon={<ChevronRightIcon />}>
            <Box
              sx={{
                fontWeight: 'bolder',
                fontSize: EPixel.px16,
                lineHeight: EPixel.px24,
              }}
            >
              <Text field={props.fields?.title} />
            </Box>
          </MuiAccordionSummary>
          <MuiAccordionDetails>
            <Placeholder name="list-item-placeholders" rendering={props.rendering} />
          </MuiAccordionDetails>
        </StyledMuiAccordion>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<ListItemProps>(ListItem);
